import styled from "styled-components";
const Allright = styled.footer`
  text-align: center;
  font-size: 14px;
  color: #3e0904;
  background: #a2ddfa;
`;

const Index = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Allright className="py-[8px]">
      <p className="text-[14px] mb-[0]">
        &copy; {currentYear} Traviya. All rights reserved.
      </p>
    </Allright>
  );
};

export default Index;
