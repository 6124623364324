import Link from "next/link";
import { useState } from "react";
import { header } from "../../../constant/header";
import { Nav } from "./styled";
import { tailwindClasses } from "./tailwindClassess";

const Navigation = () => {
  const { firstTrail, linkClass } = tailwindClasses;
  const [isActive, setIsActive] = useState(true);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <Nav className="flex justify-between">
      <div className={firstTrail}>
        {(header?.content || []).map((link) => {
          return (
            <>
              {link?.slug ? (
                <Link
                  key={link.id}
                  className={linkClass}
                  href={link.slug}
                  onClick={handleToggle}
                >
                  {link.label}
                </Link>
              ) : (
                <ul
                  key={link.id}
                  className={`${linkClass} cursor-pointer parent relative`}
                >
                  <li>
                    {link.label}
                    <ul
                      className={`${
                        isActive ? "active" : "noactive"
                      } child absolute bg-[#384148] w-full max-w-[200px] left-[-10px] p-[10px]`}
                    >
                      {link?.child.map((child) => {
                        return (
                          <li key={child.id} className="py-[8px]">
                            <Link key={child.id} href={child.slug}>
                              {child.label}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              )}
            </>
          );
        })}
      </div>
    </Nav>
  );
};

export default Navigation;
