import Link from "next/link";
import Logo from "../logo";
import Navigation from "../navigation";

const Index = () => {
  return (
    <div
      className={`py-[16px] absolute top-[0] w-full z-[999] bg-[#67b6cb-] bg-[#384148]`}
    >
      <div className="flex justify-between main-wrapper m-auto">
        <Link tabindex="0" href="/">
          <Logo />
        </Link>
        <Navigation />
      </div>
    </div>
  );
};

export default Index;
