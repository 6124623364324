import Link from "next/link";
import CopyRight from "../copyright";
import { footerLinks } from "./constant";
import { Footer } from "./style";

const Index = () => {
  return (
    <>
      <Footer className="lp-8">
        <div className="main-wrapper">
          <div className="footer-contact pd">
            <div className="row">
              <div className="col-3 footer-links footer-col">
                <div className="row">
                  {footerLinks.column_1.map((item, index) => {
                    return (
                      <div className="col-12 mb-[6px]" key={index}>
                        <Link
                          tabindex="0"
                          href={item.href}
                          target={item?.target}
                        >
                          {item.title}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-3 footer-col">
                {footerLinks.column_2.map((item, index) => {
                  return (
                    <div className="col-12 mb-[6px]" key={index}>
                      <Link tabindex="0" href={item.href} target={item?.target}>
                        {item.title}
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div className="col-3 footer-col">
                {footerLinks.column_3.map((item, index) => {
                  return (
                    <div className="col-12 mb-[6px]" key={index}>
                      <Link tabindex="0" href={item.href} target={item?.target}>
                        {item.title}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="footer-social"></div>
        </div>
      </Footer>
      <CopyRight />
    </>
  );
};

export default Index;
