export const header = {
  content: [
    {
      id: "ABOUT",
      label: "About",
      slug: "/about-us",
      target: "",
    },
    {
      id: "FEATURE",
      label: "Feature",
      slug: "/feature",
      target: "",
    },
    {
      id: "CONTACT",
      label: "Contact",
      slug: "/contact",
      target: "",
    },
    {
      id: "EXPOLRE_CITIES",
      label: "EXPOLRE CITIES",
      slug: "",
      target: "",
      child: [
        {
          id: "DELHI",
          label: "Delhi",
          slug: "/travel-destination/delhi",
          target: "",
        },
        {
          id: "BENGALURU",
          label: "BEANGALURU",
          slug: "/travel-destination/bengaluru",
          target: "",
        },
        {
          id: "MORE_CITY",
          label: "MORE CITY",
          slug: "/places",
          target: "",
        },
      ],
    },
    // {
    //   id: "ITINERARY",
    //   label: "Itinerary",
    //   slug: "/itinerary-plan-list",
    //   target: "",
    // },
  ],
};
