import styled from "styled-components";

export const Nav = styled.div`
  .child {
    display: none;
    position: absolute;
    top: 25px;
    padding: 10px;
  }
  .parent:hover .child {
    display: block;
  }
`;
