import { useRouter } from "next/router";
import Footer from "../components/layout/footer";
import HeadTag from "../components/layout/head-tag";
import Header from "../components/layout/header";
import "../styles/globals.css";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  let cls =
    router.asPath == "/"
      ? "home-page"
      : `internal-page${router.asPath.split("/").join(" ")}`;
  return (
    <>
      <HeadTag />
      <div className={cls}>
        <Header />
        <Component {...pageProps} />
        <Footer />
      </div>
    </>
  );
}

export default MyApp;
